import React, { useState } from 'react'
import { Text } from 'rebass'
import { darken, lighten } from 'polished'

import AppBody from '../AppBody'
import styled from 'styled-components'
import { useIsDarkMode } from '../../state/user/hooks'
import { colors } from '../../theme'
import { AutoColumn } from '../../components/Column'
import { ButtonPrimary } from '../../components/Button'

const StyledColumnHeader = styled.td`
  padding: 1rem 0.5rem 0.65rem 0.5rem;
  font-size: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.bg5};
`

const StyledRow = styled.tr`
  opacity: 0.9;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};

  &:last-child{
    border-bottom: none;
    td:first-child {
      border-bottom-left-radius: 10px;
    }
    td:last-child {
      border-bottom-right-radius: 10px;
    }
  }

  &.checked {
    background-color: ${({ theme }) => lighten(0.1, theme.bg2)};
  }

  &:nth-child(even){
    background-color: ${({ theme }) => darken(0.05, theme.bg1)};
    &.checked {
      background-color: ${({ theme }) => darken(0.1, theme.bg1)};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.bg3};
  }

  &.checked {
    opacity: 1;
  }
`

const StyledColumn = styled.td`
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  font-size: 16px;
  
  &:last-child {
    color: ${({ theme }) => theme.primary1};
  }
`

const StyledCheckbox = styled.input`
  appearance: none;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  margin-right: 10px;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 60ms transform ease-in-out;
    box-shadow: inset 1em 1em currentColor;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:checked::before {
    transform: scale(1);
  }
`


/*

interface MultiSelectProps {
  options: { id: number, title: string }[]
  selected: number[]
  toggleOption: (a: number) => void
}

const SearchedItems = styled.ul`
  list-style: none;
  padding: 0.2em 0;
  margin: 0;
  position: relative;
  margin-top: 5px;
  border-radius: 15px;
  border-top-color: transparent;
`

const SearchedItem = styled.li`
  cursor: pointer;
  padding: 0.5em 1em;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};

  &:first-child {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  &:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.bg3};
  }

  &.selected {
    background-color: ${({ theme }) => theme.bg4};
  }
`
function MultiSelectDropdown({ options, selected, toggleOption }: MultiSelectProps) {
  const {t} = useTranslation();
  const [showItems, setShowItems] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>('')  

  return (
    <div onFocus={() => setShowItems(true)}>
      <SearchInput
        type="search"
        id="token-search-input"
        placeholder={t('selectPools')}
        value={showItems || selected?.length === 0 ? searchQuery : t('selected') + ` (${selected.length})` }
        ref={null}
        onChange={(val) => {setSearchQuery(val.target.value)}}
        onKeyDown={() => { }}
      />
      {showItems && (
        <SearchedItems>
          {options.map(option => {
            return (
              <SearchedItem key={option.id} className={selected.includes(option.id) ? 'selected' : ''} onClick={() => {
                toggleOption(option.id)
                setSearchQuery('')
                setShowItems(false)
              }}>
                  <span>{option.title}</span>
              </SearchedItem>
            )
          })}
        </SearchedItems>
      )}
    </div>
  )
}*/

interface TempPool {
  id: number,
  title: string,
  value: string,
  APR: string,
}

export default function Reward() {
  const isDark = useIsDarkMode()
  const [selectedRewards, setSelectedRewards] = useState<TempPool[]>([])

  const options: TempPool[] = [{
    id: 1,
    title: 'ETH',
    value: '20.00',
    APR: '25%',
  },
  {
    id: 2,
    title: 'BTC',
    value: '20.00',
    APR: '25%',
  },
  {
    id: 3,
    title: 'QOM',
    value: '20.00',
    APR: '25%',
  },
  {
    id: 4,
    title: 'QWOM',
    value: '20.00',
    APR: '25%',
  },
  {
    id: 5,
    title: 'WETH',
    value: '20.00',
    APR: '25%',
  },
  ]

  return (
    <>
      <AppBody>
        <Text fontWeight={500} fontSize={20} style={{ marginBottom: 20 }}>
          Reward
        </Text>
        <AutoColumn gap="lg">
          <table style={{ width: '100%' }} cellSpacing={0}>
            <thead>
              <tr>
                <StyledColumnHeader>Token</StyledColumnHeader>
                <StyledColumnHeader style={{ textAlign: 'end' }}>Value</StyledColumnHeader>
              </tr>
            </thead>
            <tbody>
              {options.map(option => (
                <StyledRow className={!!selectedRewards.find(selected => selected.id === option.id) ? 'checked' : ''} key={option.id} onClick={() => {
                  if (selectedRewards.find(selected => selected.id === option.id)) {
                    setSelectedRewards(selectedRewards.filter(selected => selected.id !== option.id))
                  } else {
                    setSelectedRewards([...selectedRewards, option])
                  }
                }}>
                  <StyledColumn style={{display: 'grid', gap: 15, gridTemplateColumns: '1em auto'}}>
                    <StyledCheckbox type="checkbox" checked={!!selectedRewards.find(selected => selected.id === option.id)} onChange={() => {}}/>
                    {option.title}
                  </StyledColumn>
                  <StyledColumn style={{ textAlign: 'end' }}>
                    ${option.value} <span style={{ color: colors(isDark).bg5, fontSize: 14, verticalAlign: 'top' }}>({option.APR})</span>
                  </StyledColumn>
                </StyledRow>
              ))}
            </tbody>
          </table>
          <ButtonPrimary disabled={selectedRewards.length === 0} id="join-pool-button" style={{ padding: 16 }}>
              <Text fontWeight={500} fontSize={20}>
                Claim rewards
              </Text>
          </ButtonPrimary>
        </AutoColumn>
      </AppBody>
    </>
  )
}
