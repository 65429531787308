import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Text } from 'rebass'
import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'

import AppBody from '../AppBody'

import { Container, InputPanel, StyledA} from './styleds'
import Input from '../../components/NumericalInput'
import { Tabs } from '../../components/NavigationTabs'
import { useTranslation } from 'react-i18next'

type StakeMode = 'stake' | 'unstake';

export default function Stake() {
  const [value, setValue] = useState('');
  const { t } = useTranslation()
  const [active, setActive] = useState<StakeMode>('stake');

  return (
    <>
      <AppBody>
          <Tabs style={{ marginBottom: '20px' }}>
            <StyledA onClick={() => setActive('stake')} className={active === 'stake' ? 'ACTIVE' : ''}>
              {t('stake')}
            </StyledA>
            <StyledA onClick={() => setActive('unstake')} className={active === 'unstake' ? 'ACTIVE' : ''}>
              {t('unstake')}
            </StyledA>
          </Tabs>
          <AutoColumn gap="lg" justify="center">
          <InputPanel style={{width: "100%"}}>
              <Container>
                <div style={{padding: '1.75rem 0.75rem 1.75rem 1rem',}}>
                  <Input
                      className="token-amount-input"
                      value={value}
                      placeholder='0.0'
                      style={{width: "100%", textAlign: 'center'}}
                      onUserInput={val => {
                        setValue(val)
                      }}
                    />
                </div>
              </Container>
            </InputPanel>
            <ButtonPrimary id="join-pool-button" as={Link} style={{ padding: 16 }} to="/add/ETH">
              <Text fontWeight={500} fontSize={20}>
                Approve
              </Text>
            </ButtonPrimary>
          </AutoColumn>

      </AppBody>
    </>
  )
}
