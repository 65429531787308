// import { ChainId } from '@uniswap/sdk'
import React from 'react'
// import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'

import styled from 'styled-components'

// import Logo from '../../assets/svg/logo.svg'
// import LogoDark from '../../assets/svg/logo_white.svg'
// import Wordmark from '../../assets/svg/wordmark.svg'
// import WordmarkDark from '../../assets/svg/wordmark_white.svg'
// import QOM1 from '../../assets/svg/qom1.svg'
// import QOM2 from '../../assets/svg/qom2.svg'
import QOM3 from '../../assets/svg/qom3.svg'
import QOM6 from '../../assets/svg/qom6.svg'
// import QOM4 from '../../assets/svg/qom4.svg'
// import QOM5 from '../../assets/svg/qom5.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'

// import { QOMCard } from '../Card'
import Settings from '../Settings'
// import Menu from '../Menu'

import Row, { RowBetween } from '../Row'
import Web3Status from '../Web3Status'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

const TitleText = styled(Row)`
  width: fit-content;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`

// const TestnetWrapper = styled.div`
//   white-space: nowrap;
//   width: fit-content;
//   margin-left: 10px;
//   pointer-events: auto;
// `

// const NetworkCard = styled(QOMCard)`
//   color: ${({ theme }) => theme.text1};
//   width: fit-content;
//   margin-right: 10px;
//   border-radius: 12px;
//   padding: 8px 12px;
// `

// const UniIcon = styled.div`
//   transition: transform 0.3s ease;
//   :hover {
//     transform: rotate(-5deg);
//   }
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     img {
//       width: 4.5rem;
//     }
//   `};
// `

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    align-items: flex-center;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

// const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
//   [ChainId.MAINNET]: null,
//   [ChainId.RINKEBY]: 'Rinkeby',
//   [ChainId.ROPSTEN]: 'Ropsten',
//   [ChainId.GÖRLI]: 'Görli',
//   [ChainId.KOVAN]: 'Kovan',
//   [ChainId.QOM_TESTNET]: 'QOM_TESTNET'
// }

export default function Header() {
  const { account } = useActiveWeb3React()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [isDark] = useDarkModeManager()

  return (
    <HeaderFrame style={{ background: isDark ? '#393E45' : '#fbfcfd' }}>
      <RowBetween style={{ alignItems: 'center', padding: '1rem' }}>
        <HeaderElement>
          <Title href=".">
            {/* <UniIcon>
              <img src={QOM5} alt="logo" height={42} />
            </UniIcon> */}
            <TitleText>
              {isDark ? (
                <img style={{ marginLeft: '4px', marginTop: '4px' }} src={QOM3} alt="logo" height={64} />
              ) : (
                <img style={{ marginLeft: '4px', marginTop: '4px' }} src={QOM6} alt="logo" height={64} />
              )}
            </TitleText>
          </Title>
        </HeaderElement>
        <HeaderControls>
          <HeaderElementWrap style={{ paddingRight: '1rem' }}>
            <Settings />
            {/* <Menu /> */}
          </HeaderElementWrap>
          <HeaderElement>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} QOM
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
            {/* <TestnetWrapper>
              {!isMobile && chainId && NETWORK_LABELS[chainId] && (
                <NetworkCard style={{ padding: '1rem' }}>{NETWORK_LABELS[chainId]}</NetworkCard>
              )}
            </TestnetWrapper> */}
          </HeaderElement>
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  )
}
