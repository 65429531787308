import React from 'react'
import Reward from '../Reward'
import Stake from '../Stake'
import { AutoColumn } from '../../components/Column'


export default function StakeContainer() {

  return (
    <>
      <AutoColumn gap='lg'>
        <Stake />
        <Reward />
      </AutoColumn>
    </>
  )
}
